import { SystemMetricView } from './SystemMetricView';
import { MerchantMetricView } from './MerchantMetricView';
import { useEffect, useState } from 'react';
import { getDifferenceInDays, MetricType } from './type';
import QbonDatePicker from '../library/DatePicker';
import dayjs from 'dayjs';
import Combobox from '../library/Combobox';
import { ErrorMessage } from '../library/ErrorMessage';
import { ArrowRightIcon } from '@heroicons/react/solid';

interface Props {
  isSystemMetrics: boolean;
  defaultMetricType: MetricType;
  primaryColor: string;
  couponId?: number;
  raffleId?: number;
}

const adminOptions: { title: string, value: MetricType }[] = [
  { value: 'MERCHANT_CREATED', title: 'Merchants Created Count' },
  { value: 'USER_CREATED', title: 'Users Created Count' },
];

const merchantOptions: { title: string, value: MetricType }[] = [
  { value: 'STORE_FRONT_ARRIVED', title: 'Tap and Scan Count' },
  { value: 'LINK_CLICKED', title: 'Links Clicked' },
  { value: 'SOCIAL_SHARE', title: 'Social Platforms Shared To' },
  { value: 'COUPON_CLAIMED', title: 'Qbons Claimed Count' },
  { value: 'COUPON_REDEEMED', title: 'Qbons Redeemed Count' },
  { value: 'COUPON_CREATED', title: 'Qbons Created Count' },
  {
    value: 'CONTENT_POSTED', title: 'Content Posted By User Count',
  },
  {
    value: 'COUPON_VIEWED', title: 'Qbon Views',
  },
  {
    value: 'RAFFLE_ENTERED', title: 'Raffle Entries',
  },
];

export function MetricView({ primaryColor, isSystemMetrics, defaultMetricType, couponId, raffleId }: Props) {
  const [metric, setMetric] = useState<{
    value: MetricType,
    title: string;
  }>();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [error, setError] = useState<string[]>([]);

  useEffect(() => {
    setToDate(new Date());
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    setFromDate(thirtyDaysAgo);

    const initialMetric = isSystemMetrics ? adminOptions.find((m) => m.value === defaultMetricType) || adminOptions[0] :
      merchantOptions.find((m) => m.value === defaultMetricType) || merchantOptions[0];
    setMetric(initialMetric);
  }, [defaultMetricType, isSystemMetrics]);

  return (
    <>
      {metric && <div className="py-1.5 w-full">
        {toDate && fromDate && <div className={"p-2"}>
          <p className="text-[#fb3310] p-4">
            <ErrorMessage message={error} />
          </p>
          {!raffleId && !couponId &&
            <div className={'mb-2 p-2 rounded-xl'}>
              <div className={''}>
                <Combobox value={metric}
                          options={isSystemMetrics ? adminOptions : merchantOptions.filter((o) => o.value !== 'COUPON_VIEWED' && o.value !== 'RAFFLE_ENTERED')}
                          onChange={(val) => setMetric(val)}
                          displayKey={'title'}
                label={"Metric"}/>
              </div>
            </div>
          }
          <div className={'mb-6 rounded-xl'}>
            <div className={'flex-row flex m-auto '}>
              <div className={'w-[220px]'}>
                <QbonDatePicker value={dayjs(fromDate)} onChange={(date) => {
                  const newDate = date?.toDate();
                  if (newDate) {
                    const val = getDifferenceInDays(newDate, toDate);
                    if (val > 180) {
                      setFromDate(fromDate);
                      setError(['Dates must be within 180 days of each other']);
                    } else {
                      setError([]);
                      setFromDate(newDate);
                    }
                  }
                }} />
              </div>
              <div className={'pl-4 pt-4 font-bold'}>
                <ArrowRightIcon width={14} height={14}/>
              </div>
              <div className={'w-[220px]'}>
                <QbonDatePicker value={dayjs(toDate)} onChange={(date) => {
                  const newDate = date?.toDate();
                  if (newDate) {
                    const val = getDifferenceInDays(newDate, toDate);
                    if (val > 180) {
                      setError(['Dates must be within 180 days of each other']);
                      setToDate(toDate);
                    } else {
                      setError([]);
                      setToDate(newDate);
                    }
                  }
                }} />
              </div>
            </div>
          </div>
          <div>
            {
              isSystemMetrics ? (<SystemMetricView metricType={metric.value} fromDate={fromDate} toDate={toDate}
                                                   primaryColor={primaryColor} />) :
                (<MerchantMetricView metricType={metric.value} fromDate={fromDate} toDate={toDate} raffleId={raffleId}
                                     couponId={couponId} primaryColor={primaryColor} />)
            }
          </div>
        </div>}
      </div>}
    </>
  );
}