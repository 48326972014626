import { useEffect, useState } from 'react';
import BasicForm, { FormField } from '../../../common/library/BasicForm';

interface Props {
  onChange: (raffleData: any) => void;
}

export default function CreateRaffleInfo({ onChange }: Props) {
  const [raffleData, setRaffleData] = useState<any>({
    name: '',
    description: '',
    code: '',
    startDate: new Date(),
  });

  useEffect(() => {
    onChange(raffleData);
  }, [raffleData, onChange]);

  const fields: FormField[] = [
    {
      label: 'Name',
      required: true,
      value: raffleData.name,
      onValueChange: (name) => {
        const newData = { ...raffleData, name };
        setRaffleData(newData);
      },
    },
    {
      label: 'Description',
      required: true,
      value: raffleData.description,
      onValueChange: (description) => {
        const newData = { ...raffleData, description };
        setRaffleData(newData);
      },
    },
    {
      label: 'Date',
      value: raffleData.startDate?.toString(),
      type: 'date',
      onValueChange: (startDate) => {
        const newData = {
          ...raffleData,
          startDate: new Date(startDate),
          endDate: new Date(startDate)
        };
        setRaffleData(newData);
      },
    },
  ];

  return (
    <>
      <div className="py-4">
        <BasicForm fields={fields} />
      </div>
    </>
  );
}
