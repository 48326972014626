import useAuthContext from '../../../hooks/auth-context.hook';
import CreateRaffleInfo from './CreateRaffleInfo';
import CreateRaffleQbon from './CreateRaffleQbon';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router';
import Card from '../../../common/library/Card';
import Button from '../../../common/library/Button';
import { useRaffles } from '../../../hooks/raffle.hook';
import { useRef, useState } from 'react';
import { ErrorMessage } from '../../../common/library/ErrorMessage';

export default function CreateRafflePage() {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const errorDivRef = useRef(null);

  const { user, merchant } = auth.getMerchantAndUser();
  const { useCreateRaffleMutation } = useRaffles();
  const createRaffleMutation = useCreateRaffleMutation(merchant.id, () => {
    navigate('/dashboard/giveaways');
  }, (error) => {
    setErrors(error.message);
  });

  const [errors, setErrors] = useState<string[]>([]);
  const [raffleData, setRaffleData] = useState<any>({});
  const [couponData, setCouponData] = useState<any>();

  const validateAndCreateRaffle = () => {
    setErrors([]);

    if (!raffleData || !couponData) {
      setErrors(['Raffle and Qbon data must be filled out!']);
      scrollToTop();
      return;
    }

    if (raffleData.name === '') {
      setErrors(['Raffle name must be filled out!']);
      scrollToTop();
      return;
    }

    if (raffleData.description === '') {
      setErrors(['Raffle description must be filled out!']);
      scrollToTop();
      return;
    }

    if (couponData.name === '') {
      setErrors(['Coupon name must be filled out!']);
      scrollToTop();
      return;
    }

    if (couponData.description === '') {
      setErrors(['Coupon description must be filled ou!']);
      scrollToTop();
      return;
    }

    if (couponData.startDate > couponData.endDate) {
      setErrors(['Raffle start date must be set to before it\'s end date!']);
      scrollToTop();
      return;
    }

    createRaffleMutation.mutate({
      name: raffleData.name,
      description: raffleData.description,
      startDate: raffleData.startDate,
      endDate: raffleData.startDate,
      coupon: {
        name: couponData.name,
        description: couponData.description,
        type: 'SHARE',
        code: couponData.code,
        startDate: couponData.startDate,
        endDate: couponData.endDate,
        status: 'AVAILABLE',
        discountType: couponData.discountType,
        discountAmount: couponData.discountAmount,
        couponImageUrl: couponData.couponImageUrl,
        redemptionMethodId: couponData.redemptionMethodId,
      },
    });
  };

  const scrollToTop = () => {
    errorDivRef &&
    errorDivRef.current &&
    (errorDivRef.current as any).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <>
      <div className="flex flex-row">
        <div>
          <h1
            className="font-bold text-xl pb-4 px-2 md:px-0 underline cursor-pointer text-[#fb3310]"
            onClick={() => navigate('/dashboard/giveaways')}
          >
            Giveaways
          </h1>
        </div>
        <div>
          <ChevronRightIcon
            className="h-6 w-6 text-gray-500 pt-1"
            aria-hidden="true"
          />
        </div>
        <div>
          <h1 className="font-bold text-xl pb-4 px-2 md:px-0">
            Create Raffle
          </h1>
        </div>
      </div>
      <div>
        <div className="py-2" ref={errorDivRef}>
          <ErrorMessage message={errors} />
        </div>
        <Card>
          <div>
            <h1
              className="font-bold text-lg"
            >
              Info
            </h1>
          </div>
          <CreateRaffleInfo onChange={(raffle) => {
            setRaffleData(raffle);
          }} />
        </Card>
      </div>
      <div className={'pt-2'}>
        <Card>
          <h1
            className="font-bold text-lg"
          >
            Qbon
          </h1>
          <CreateRaffleQbon merchant={merchant} user={user} onChange={(couponData) => {
            setCouponData(couponData);
          }} />
        </Card>
      </div>

      <div className={'pt-2 pb-2'}>
        <Button label={'Create Raffle'} buttonWidth={'full'} onClick={validateAndCreateRaffle}
                loading={createRaffleMutation.status === 'pending'} />
      </div>
    </>
  );
}
