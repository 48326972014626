import { useCallback, useRef, useState } from 'react';
import CouponComp from '../../../common/Coupon/CouponComp';
import BasicForm, { FormField } from '../../../common/library/BasicForm';
import Button from '../../../common/library/Button';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import ImageCropModal from '../../../common/library/ImageCropper';
import useQuery from '../../../hooks/query-params.hook';
import { useNavigate } from 'react-router';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useMerchantFiles } from '../../../hooks/merchant-files.hook';
import useCoupons from '../../../hooks/coupon.hook';
import useAuthContext from '../../../hooks/auth-context.hook';
import { CouponType } from '../../../models/coupon';
import { toast } from 'react-toastify';

export default function CreateQbon() {
  const { merchant, user } = useAuthContext().getMerchantAndUser();

  const query = useQuery();
  const navigate = useNavigate();
  const errorDivRef = useRef(null);
  const couponType = query.get('type');

  const { useCreateCouponMutation, useCouponRedemptionMethodsQuery } = useCoupons();
  const { data: redemptionMethods } = useCouponRedemptionMethodsQuery(merchant.id);
  const createCoupon = useCreateCouponMutation(merchant.id,
    () => {
      setFile(undefined);
      setCouponData({
        name: '',
        description: '',
        type: couponType as CouponType,
        code: '',
        startDate: null,
        endDate: null,
        isSingleRedemption: true,
        pointsRequired: '20',
      });
      setFile(undefined);
      setCouponImageUrl(undefined);
      navigate('/dashboard/qbons');
      toast.success('Qbon created!');
    },
    (error) => toast('An error occurred communicating with the server!', { type: 'error' }));

  const { useUploadMerchantFileMutation } = useMerchantFiles();
  const uploadCouponCoverImage = useUploadMerchantFileMutation(user.id, merchant.id, (res) => {
    setCouponImageUrl(res.fileUrl);
  }, () => {
    toast('An error occurred uploading coupon cover image', { type: 'error' });
  });

  const [file, setFile] = useState<File>();
  const [couponImageUrl, setCouponImageUrl] = useState<string>();
  const [cropModalOpen, setCropModalOpen] = useState<boolean>(false);
  const [cropSrc, setCropSrc] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [couponData, setCouponData] = useState<any>({
    name: '',
    description: '',
    type: couponType as CouponType,
    code: '',
    startDate: null,
    endDate: null,
    discountType: null,
    discountAmount: null,
    redemptionMethod: null,
    isSingleRedemption: true,
  });

  const fields: FormField[] = [
    {
      label: 'Name',
      required: true,
      value: couponData.name,
      onValueChange: (name) => {
        if (name.length >= 48) {
          return;
        }
        const newData = { ...couponData, name };
        setCouponData(newData);
      },
    },
    {
      label: 'Description',
      required: true,
      value: couponData.description,
      onValueChange: (description) => {
        const newData = { ...couponData, description };
        setCouponData(newData);
      },
    },
    {
      label: 'Redemption Method',
      value: couponData.redemptionMethod || redemptionMethods[0]?.name,
      combobox: true,
      options: redemptionMethods.map((method) => method.name),
      onValueChange: (value) => {
        if (value) {
          const newData = { ...couponData, redemptionMethod: value };
          setCouponData(newData);
        }
      },
    },
    {
      label: 'Discount Type',
      value:
        couponData.discountType === null
          ? 'Disabled'
          : couponData.discountType === 'AMOUNT'
            ? 'Amount ($)'
            : 'Percentage (%)',
      combobox: true,
      options: ['Disabled', 'Amount ($)', 'Percentage (%)'],
      onValueChange: (value) => {
        if (value === 'Disabled') {
          const newData = { ...couponData, discountType: null };
          setCouponData(newData);
        }
        if (value === 'Amount ($)') {
          const newData = { ...couponData, discountType: 'AMOUNT' };
          setCouponData(newData);
        }
        if (value === 'Percentage (%)') {
          const newData = { ...couponData, discountType: 'PERCENTAGE' };
          setCouponData(newData);
        }
      },
    },
    ...(couponData.discountType !== null
      ? [
        {
          label: 'Discount Amount',
          value:
            couponData.discountAmount === null
              ? couponData.discountType === 'PERCENTAGE'
                ? `%1`
                : 1
              : `${
                couponData.discountType === 'PERCENTAGE' ? '%' : '$'
              }${parseFloat(couponData.discountAmount).toFixed(2)}`,
          onValueChange: (value: string) => {
            let input = value
              .replace('%', '')
              .replace('$', '')
              .replace(/[^0-9]/g, ''); // Remove non-numeric characters
            if (input.length > 10) {
              input = input.slice(0, 4);
            }
            while (input.length < 10) {
              input = '0' + input;
            }
            // Insert decimal point before the last two digits
            input =
              input.slice(0, input.length - 2) +
              '.' +
              input.slice(input.length - 2);
            const val = parseFloat(input);

            setCouponData({
              ...couponData,
              discountAmount:
                val > 100 && couponData.discountType === 'PERCENTAGE'
                  ? 100
                  : val < 0 && couponData.discountType === 'PERCENTAGE'
                    ? 1
                    : val > 1000000 && couponData.discountType === 'AMOUNT'
                      ? 1000000
                      : val,
            });
          },
        } as FormField,
      ]
      : []),
    {
      label: 'Discount Code',
      value: couponData.code,
      onValueChange: (code) => {
        const newData = { ...couponData, code };
        setCouponData(newData);
      },
    },
    {
      label: 'Start Date',
      value: couponData.startDate?.toString(),
      type: 'date',
      onValueChange: (startDate) => {
        const newData = {
          ...couponData,
          startDate: new Date(startDate),
        };
        setCouponData(newData);
      },
    },
    {
      label: 'Expire Date',
      value: couponData.endDate?.toString(),
      type: 'date',
      onValueChange: (endDate) => {
        const newData = {
          ...couponData,
          endDate: new Date(endDate),
        };
        setCouponData(newData);
      },
    },
  ];

  const executeAction = useCallback(() => {
    if (couponData.name === '' || couponData.description === '') {
      toast('Name and description are required', { type: 'error' });
      scrollToTop();
      return;
    }

    createCoupon.mutate(
      {
        ...couponData,
        status: 'AVAILABLE',
        type: couponType as CouponType,
        file,
        redemptionMethodId:
          redemptionMethods.find(
            (code) => code.name === couponData.redemptionMethod,
          )?.id || 1,
        couponImageUrl,
      },
    );
  }, [
    createCoupon,
    couponData,
    file,
    couponImageUrl,
    redemptionMethods,
    couponType,
  ]);

  const message = useCallback(() => {
    if (
      couponData.startDate === undefined ||
      couponData.startDate === null ||
      couponData.startDate < new Date()
    ) {
      return (
        <div className="bg-yellow-50 p-6 rounded-lg m-auto">
          <div className="text-yellow-700 flex md:w-[50%]">
            <div className="w-[50px] pr-6 m-auto">
              <ExclamationCircleIcon height={32} width={32} />
            </div>
            <div className="m-auto">
              Coupons with no start date, start dates of today, or start dates
              before today will be active immediately
            </div>
          </div>
        </div>
      );
    }
  }, [couponData]);

  const handleImageCropComplete = async (croppedImage: Blob | null, type: string): Promise<void> => {
    if (croppedImage) {
      const croppedFile = new File(
        [croppedImage],
        `cropped_coupon_image.${croppedImage.type}`,
        { type: croppedImage.type },
      );
      if (croppedFile) {
        if (type === 'coupon') {
          uploadCouponCoverImage.mutate(croppedFile);
        }
      }
    }
    setCropModalOpen(false);
  };

  const scrollToTop = () => {
    errorDivRef &&
    errorDivRef.current &&
    (errorDivRef.current as any).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <>
      <div className="flex flex-row">
        <div>
          <h1
            className="font-bold text-xl pb-6 px-3 md:px-0 underline cursor-pointer text-[#fb3310]"
            onClick={() => navigate('/dashboard/qbons')}
          >
            Qbons
          </h1>
        </div>
        <div>
          <ChevronRightIcon
            className="h-6 w-6 text-gray-500 pt-1"
            aria-hidden="true"
          />
        </div>
        <div>
          <h1 className="font-bold text-xl pb-6 px-3 md:px-0">
            Create Qbon
          </h1>
        </div>
      </div>
      {message()}
      <div className="py-3">
        <BasicForm fields={fields} />
        <div className="inline-flex">
          <div className="pr-1 pt-6">
            <Button
              label="Clear Start Date"
              onClick={() =>
                setCouponData({ ...couponData, startDate: null })
              }
            />
          </div>
          <div className="pl-1 pt-6">
            <Button
              label="Clear End Date"
              onClick={() => setCouponData({ ...couponData, endDate: null })}
            />
          </div>
        </div>
        <div className="py-6">
          <div className="font-bold">
            Coupon Image {'(JPEG or PNG - 16:9 Aspect Ration Suggested)'}
          </div>
          <div>
            Give your coupon an image{' '}
            <span className="text-red-400">
                (if not set default will be used)
              </span>
          </div>
          <div className="py-2 inline-flex">
            <input
              type="file"
              onChange={(event: any) => {
                setCropSrc(URL.createObjectURL(event.target.files[0]));
                setCropModalOpen(true);
                setType('coupon');
              }}
            />
            <Button
              label="Remove"
              onClick={() => {
                setCouponImageUrl(undefined);
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full lg:w-[550px]">
        <div className="flex justify-center">
          <CouponComp
            merchantLogoUrl={merchant.logoImageUrl}
            description={couponData.description!}
            endDate={couponData.endDate!}
            startDate={couponData.startDate}
            code={couponData.code}
            couponImageUrl={couponImageUrl}
            discountAmount={couponData.discountAmount}
            discountType={couponData.discountType}
          />
        </div>
      </div>
      <div className="pt-12 pb-4 w-full lg:w-[550px]">
        <Button
          label="Create Qbon"
          buttonWidth="full"
          loading={createCoupon.status === 'pending'}
          onClick={executeAction}
        />
      </div>

      {cropModalOpen && (
        <ImageCropModal
          src={cropSrc}
          aspect={[16, 9]}
          onComplete={(croppedImage: Blob | null) =>
            handleImageCropComplete(croppedImage, type)
          }
          onClose={() => setCropModalOpen(false)}
        />
      )}
    </>
  );
}
