import './Coupon.css';
import Button from '../library/Button';

interface Props {
  merchantLogoUrl?: string;
  description: string;
  startDate?: Date | null;
  endDate?: Date | null;
  code?: string;
  couponImageUrl?: string;
  name?: string;
  discountType?: string;
  discountAmount?: number;
}

export default function CouponComp({
                                     description,
                                     startDate,
                                     endDate,
                                     code,
                                     couponImageUrl,
                                     name,
                                     discountType,
                                     discountAmount,
                                   }: Props) {
  const dateText = () => {
    if (startDate && endDate && startDate !== null && endDate !== null) {
      return `Good ${startDate.toLocaleDateString()} thru ${endDate.toLocaleDateString()}`;
    }
    if (startDate && startDate !== null) {
      return `Starts on ${startDate.toLocaleDateString()}`;
    }

    if (endDate && endDate !== null) {
      return `expires: ${endDate.toLocaleDateString()}`;
    }

    return 'Never expires (unless merchant cancels deal)';
  };

  return (
    <>
      <div className="px-24 w-[550px]">
        <div
          style={{
            borderTopWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            borderColor: '#bbbbbb',
            boxShadow: '0px 00px 10px 0px #bbbbbb',
          }}
          className="shadow-xl"
        >
          <img
            alt="Coupon"
            src={
              couponImageUrl ||
              'https://cdn.pixabay.com/photo/2014/10/19/20/59/hamburger-494706_1280.jpg'
            }
            style={{
              height: 225,
              width: '100%',
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
            }}
          />
        </div>
        {
          /**<div
           style={{
           flexDirection: 'row',
           justifyContent: 'space-between',
           backgroundColor: 'white',
           borderRightWidth: 1,
           borderLeftWidth: 1,
           boxShadow: '0px 00px 10px 0px #bbbbbb',
           height: 0,
           }}
           >
           <div style={{ left: -20, top: -22, zIndex: 10 }} className="relative">
           <Circle classname="border-r-4" />
           </div>
           <div style={{ left: 330, top: -64, zIndex: 10 }} className="relative">
           <Circle classname="border-l-4" />
           </div>
           </div>*/
        }
        <div style={{ width: '100%' }}>
          <div
            style={{
              width: '100%',
              alignSelf: 'center',
              borderTopWidth: 5,
              bottom: 44,
              borderStyle: 'dashed',
              backgroundColor: 'white',
            }}
          />
        </div>
        <div
          style={{
            minHeight: 300,
            backgroundColor: 'white',
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            padding: 16,
            zIndex: -10,
            borderColor: '#bbbbbb',
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderBottomWidth: 1,
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              padding: 24,
            }}
          >
            <div style={{ paddingBottom: 8 }}>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: 24,
                  fontWeight: '600',
                }}
              >
                {name}
              </p>
            </div>
            {discountType && discountAmount && (
              <div>
                <p style={{ textAlign: 'center', fontSize: 12 }}>
                  {discountType === 'AMOUNT' && '$'}
                  {discountAmount}
                  {discountType === 'PERCENTAGE' && '%'}
                  {' OFF'}
                </p>
              </div>
            )}
            <div>
              <p style={{ textAlign: 'center', fontSize: 12 }}>{description}</p>
            </div>
          </div>
          {code ? (
            <div
              style={{
                alignItems: 'center',
                paddingBottom: 24,
              }}
              className="flex justify-center"
            >
              <Button label={code} variant="outline" />
            </div>
          ) : (
            <div className="flex justify-center w-[100px] border-dashed h-[50px] border-2 text-center m-auto"></div>
          )}

          <div style={{ paddingTop: 8 }}>
            <p
              style={{
                textAlign: 'center',
                fontStyle: 'italic',
              }}
              className="text-sm"
            >
              {dateText()}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
