import CouponComp from '../../../common/Coupon/CouponComp';
import { Coupon } from '../../../models/coupon';
import { Merchant } from '../../../models/merchant';

interface Props {
  coupon: Coupon;
  merchant: Merchant;
  userId: number;
  onStatusChange?: () => void;
}

export default function CouponWrapper({
                                        coupon: viewedCoupon,
                                        merchant,
                                      }: Props) {

  return (
    <>
      <div className="flex justify-center py-2">
        <div>
          <div className="py-2 m-auto">
            {viewedCoupon.discountType &&
              viewedCoupon.discountAmount && (
                <div className="text-center py-2 font-bold text-[#fb3310]">
                  {viewedCoupon.discountType === 'AMOUNT' && '$'}
                  {parseFloat(viewedCoupon.discountAmount.toString()) ===
                  100.0 && viewedCoupon.discountType === 'PERCENTAGE'
                    ? 'Free'
                    : viewedCoupon.discountAmount}
                  {viewedCoupon.discountType === 'PERCENTAGE' &&
                    parseFloat(viewedCoupon.discountAmount.toString()) <
                    100.0 &&
                    '%'}
                </div>
              )}
            <div>
              <CouponComp
                merchantLogoUrl={merchant.logoImageUrl}
                description={viewedCoupon.description}
                startDate={
                  viewedCoupon.startDate &&
                  viewedCoupon.startDate !== null
                    ? new Date(viewedCoupon.startDate)
                    : null
                }
                endDate={
                  viewedCoupon.endDate &&
                  viewedCoupon.endDate !== null
                    ? new Date(viewedCoupon.endDate)
                    : null
                }
                code={viewedCoupon.code}
                couponImageUrl={viewedCoupon.couponImageUrl}
                name={viewedCoupon.name}
                discountAmount={viewedCoupon.discountAmount}
                discountType={viewedCoupon.discountType}
              />
            </div>
            <div className="m-auto text-center pt-2 flex justify-center">
              <div className="inline-flex">
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
