import useAuthContext from '../../../hooks/auth-context.hook';
import Input from '../../../common/library/Input';
import CouponWrapper from '../merchant-coupons/CouponWrapper';
import { RaffleResponse } from '../../../models/raffle';
import Button from '../../../common/library/Button';
import { useRaffles } from '../../../hooks/raffle.hook';
import { useState } from 'react';
import { ErrorMessage } from '../../../common/library/ErrorMessage';

interface Props {
  data: RaffleResponse;
  refetch: () => void;
}

export default function RaffleInfo({ data, refetch }: Props) {
  const auth = useAuthContext();
  const { user, merchant } = auth.getMerchantAndUser();
  const [errors, setErrors] = useState<string[]>([]);

  const { useDrawRaffleMutation, useCompleteRaffleMutation } = useRaffles();
  const drawMutation = useDrawRaffleMutation(merchant.id, data.raffle.id, () => {
    refetch();
  }, (error) => {
    console.log(error);
    if (error.message) {
      setErrors(error.message);
    }
  });

  const completeMutation = useCompleteRaffleMutation(merchant.id, data.raffle.id, () => {
    refetch();
  }, () => {
  });

  return (
    <>
      <div className={"w-full lg:w-[550px]"}>
        {errors.length > 0 && <div className="py-2">
          <ErrorMessage message={errors} />
        </div>}
        <div className={'font-bold text-xl'}>
          Winner
        </div>
        <div>
          <div className={'py-2'}>
            <Input disabled value={data.winnerDetails?.name} label={'Name'} />
          </div>
          <div className={'py-2'}>
            <Input disabled value={data.winnerDetails?.entryNumber} label={'Entry Number'} />
          </div>
          <div className={'pt-4'}>
            {data.raffle.status.id === 1 &&
              <Button label={'Draw Winner'} buttonWidth={'full'} onClick={() => drawMutation.mutate()} />}
            {data.raffle.status.id === 2 &&
              <Button label={'Re-Draw Winner'} buttonWidth={'full'} onClick={() => drawMutation.mutate()} />}
            {data.raffle.status.id === 2 &&
              <div className={'pt-4'}><Button label={'Complete Raffle'} buttonWidth={'full'} variant={'outline'}
                                              onClick={() => completeMutation.mutate()} /></div>}
          </div>
        </div>
        <div className={'pt-6 font-bold text-xl'}>
          Info
        </div>
        <div>
          <div className={'py-2'}>
            <Input disabled value={data.raffle.name} label={'Name'} />
          </div>
          <div className={'py-2'}>
            <Input disabled value={data.raffle.description} label={'Description'} />
          </div>
          <div className={'py-2'}>
            <Input disabled value={data.raffle.status.name} label={'Status'} />
          </div>
          <div className={'py-2'}>
            <Input disabled value={new Date(data.raffle.startDate).toDateString()} label={'Date'} />
          </div>
        </div>

        <div className={'pt-6'}>
          <div className={'py-2 font-bold text-xl'}>
            Qbon
          </div>
          <CouponWrapper
            coupon={data.coupon}
            merchant={merchant}
            userId={user.id}
            onStatusChange={() => {
            }}
          />
        </div>
      </div>
    </>
  );
}
