import { Navigate, Route, Routes, useNavigate } from 'react-router';
import MerchantDashboard from './pages/merchant-dashboard/MerchantDashboard';
import MerchantProfilePage from './pages/merchant-dashboard/merchant-profile/MerchantProfilePage';
import { useEffect, useMemo } from 'react';
import { Home } from './pages/home/Home';
import MerchantCoupons from './pages/merchant-dashboard/merchant-coupons/MerchantCoupons';
import MerchantQRCode from './pages/merchant-dashboard/merchant-qr-code/MerchantQRCode';
import MerchantUsersPage from './pages/merchant-dashboard/merchant-users/MerchantUsersPage';
import MerchantMetricsPage from './pages/merchant-dashboard/merchant-metrics/MerchantMetrics';
import MerchantSubscriptionPage from './pages/merchant-dashboard/merchant-subscription/MerchantSubscriptionPage';
import TermsAndConditions from './pages/home/TermsAndConditions';
import CreateQbon from './pages/merchant-dashboard/merchant-coupons/CreateQbon';
import MerchantScannerPage from './pages/merchant-dashboard/merchant-scanner/MerchantScannerPage';
import MerchantScannerRedeem from './pages/merchant-dashboard/merchant-scanner/MerchantScannerRedeem';
import MerchantRaffles from './pages/merchant-dashboard/merchant-raffles/RafflesPage';
import MerchantContentPage from './pages/merchant-dashboard/merchant-content/MerchantContentPage';
import CreateRafflePage from './pages/merchant-dashboard/merchant-raffles/CreateRafflePage';
import RaffleDetailsPage from './pages/merchant-dashboard/merchant-raffles/RaffleDetailsPage';
import CreateMerchantPage from './pages/home/CreateMerchantPage';
import useAuthContext from './hooks/auth-context.hook';
import AdminDashboard from './pages/admin-dashboard/AdminDashboard';
import Licenses from './pages/admin-dashboard/Licenses';
import { SystemMetrics } from './pages/admin-dashboard/SystemMetrics';
import Devices from './pages/admin-dashboard/Devices';
import MerchantDevices from './pages/merchant-dashboard/merchant-qr-code/MerchantDevices';
import TutorialPage from './pages/merchant-dashboard/tutorial/TutorialPage';
import MerchantSingleCoupon from './pages/merchant-dashboard/merchant-coupons/MerchantSingleCoupon';
import UserInvites from './pages/user/UserInvites';
import Merchants from './pages/home/Merchants';

function App() {
  const context = useAuthContext();
  const user = context.getUser().user;
  const merchant = context.merchant;
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user.acceptedTermsAndConditions === null ||
      !user.acceptedTermsAndConditions
    ) {
      navigate('terms-and-conditions');
    }
  }, [user, navigate]);

  return useMemo(() => {
    return (
      <>
        {merchant !== undefined ? (
          <Routes>
            <Route path="dashboard" element={<MerchantDashboard />}>
              <Route path="profile" element={<MerchantProfilePage />} />
              <Route path="qr-code" element={<MerchantQRCode />} />
              <Route path="devices" element={<MerchantDevices />} />
              <Route path="qbons" element={<MerchantCoupons />} />
              <Route path="qbons/:couponId" element={<MerchantSingleCoupon />} />
              <Route path="giveaways" element={<MerchantRaffles />} />
              <Route path="giveaways/:raffleId" element={<RaffleDetailsPage />} />
              <Route path="giveaways/create" element={<CreateRafflePage />} />
              <Route path="qbons/create" element={<CreateQbon />} />
              <Route path="merchant-users" element={<MerchantUsersPage />} />
              <Route path="invites" element={<UserInvites />} />
              <Route path="scanner" element={<MerchantScannerPage />} />
              <Route
                path="scanner/redeem"
                element={<MerchantScannerRedeem />}
              />
              <Route path="metrics" element={<MerchantMetricsPage />} />
              <Route path="content" element={<MerchantContentPage />} />
              <Route
                path="subscription"
                element={<MerchantSubscriptionPage />}
              />
            </Route>
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route
              path="tutorial"
              element={<TutorialPage />}
            />
            <Route
              path="*"
              element={<Navigate to="dashboard/profile" replace />}
            />
          </Routes>
        ) : (
          <>
            <Routes>
              {
                user.type === 'SUPER_ADMIN' && (
                  <>
                    <Route path="admin" element={<AdminDashboard />}>
                      <Route path="users" element={<></>} />
                      <Route path="licenses" element={<Licenses />} />
                      <Route path="devices" element={<Devices />} />
                      <Route path="system-metrics" element={<SystemMetrics />} />
                    </Route>
                  </>
                )
              }
              <Route path="home" element={<Home />}>
                <Route path="merchants" element={<Merchants />} />
                <Route path="invites" element={<UserInvites />} />
              </Route>
              <Route
                path="terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="create-merchant" element={<CreateMerchantPage user={context!.authUser!} />} /> {/* Add the new route */}
              <Route
                path="*"
                element={<Navigate to="home/merchants" replace />}
              />
            </Routes>
          </>
        )}
      </>
    );
  }, [context, merchant, user.type]);
}

export default App;
