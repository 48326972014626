import { useFetch } from './fetch.hook';
import { MerchantFileDTO } from '../models/files';
import { useMutation } from '@tanstack/react-query';

const Routes = {
  userMerchantFiles: (userId: number, merchantId: number) =>
    `/users/${userId}/merchants/${merchantId}/media`,
};

export function useMerchantFiles() {
  const { fetchJsonFunc } = useFetch();

  const useUploadMerchantFileMutation = (
    userId: number,
    merchantId: number,
    onSuccess: (res: MerchantFileDTO) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (file: File) => {
        var data = new FormData();
        data.append('file', file);
        return fetchJsonFunc<MerchantFileDTO>(
          Routes.userMerchantFiles(userId, merchantId),
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            method: 'POST',
            body: data,
          },
          false,
        );
      },
      onSuccess,
      onError,
    });
  };

  return {
    useUploadMerchantFileMutation,
  };
}
