import useQuery from '../../../hooks/query-params.hook';
import { useCallback, useRef, useState } from 'react';
import useCoupons from '../../../hooks/coupon.hook';
import { useMerchantFiles } from '../../../hooks/merchant-files.hook';
import BasicForm, { FormField } from '../../../common/library/BasicForm';
import { ErrorMessage } from '../../../common/library/ErrorMessage';
import Button from '../../../common/library/Button';
import CouponComp from '../../../common/Coupon/CouponComp';
import ImageCropModal from '../../../common/library/ImageCropper';
import { Merchant } from '../../../models/merchant';
import { User } from '../../../models/users';
import InfoBox from '../../../common/InfoBox';
import WarningBox from '../../../common/WarningBox';

interface Props {
  merchant: Merchant;
  user: User;
  onComplete: () => void;
}

export default function CreateQbonTutorial({ merchant, user, onComplete }: Props) {
  const query = useQuery();
  const errorDivRef = useRef(null);
  const parentCouponId = query.get('parentCouponId')
    ? parseInt(query.get('parentCouponId')!)
    : undefined;

  const { useCreateCouponMutation, useCouponRedemptionMethodsQuery } = useCoupons();
  const { data: redemptionMethods } = useCouponRedemptionMethodsQuery(merchant.id);
  const createCoupon = useCreateCouponMutation(merchant.id,
    () => {
      setFile(undefined);
      setCouponData({
        name: '',
        description: '',
        type: 'PRIMARY',
        code: '',
        startDate: null,
        endDate: null,
        pointsRequired: '20',
      });
      setFile(undefined);
      setCouponImageUrl(undefined);
      setErrorMessage([]);
      onComplete();
    },
    () => setErrorMessage(['Please make sure to fill in name and description']));

  const { useUploadMerchantFileMutation } = useMerchantFiles();
  const uploadCouponCoverImage = useUploadMerchantFileMutation(user.id, merchant.id, (res) => {
    setCouponImageUrl(res.fileUrl);
  }, () => {
    setErrorMessage(['An error occurred coupon cover image']);
  });

  const [file, setFile] = useState<File>();
  const [couponImageUrl, setCouponImageUrl] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [cropModalOpen, setCropModalOpen] = useState<boolean>(false);
  const [cropSrc, setCropSrc] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [couponData, setCouponData] = useState<any>({
    name: '',
    description: '',
    type: parentCouponId ? 'CHILD' : 'PRIMARY',
    code: '',
    parentCouponId,
    startDate: null,
    endDate: null,
    discountType: null,
    discountAmount: null,
    redemptionMethod: null,
  });

  const fields: FormField[] = [
    {
      label: 'Name',
      helpTipWritten: 'This is the name for your Qbon that will display on the app. This should be something like 50% OFF COMBO.',
      required: true,
      value: couponData.name,
      onValueChange: (name) => {
        if (name.length >= 48) {
          return;
        }
        const newData = { ...couponData, name };
        setCouponData(newData);
      },
    },
    {
      label: 'Description',
      helpTipWritten: 'This is the description for the discount you are giving, here you write the exact item the discount applies to and any specific rules for the discount such as Only Applies on Weekends.',
      required: true,
      value: couponData.description,
      onValueChange: (description) => {
        const newData = { ...couponData, description };
        setCouponData(newData);
      },
    },
    {
      label: 'Redemption Method',
      helpTipWritten: 'Redemption method is the way a customer redeems the discount at your business, by default they will have a button and QR code available so they may either click on the button and show you they redeemed or you scan the QR code on their Qbon with the merchant scanner on your dashboard to redeem their discount. When a Qbon is redeemed, the Code that you will set next will be revealed. If you redeem the Qbon with a scanner, only you as the merchant will see the discount code. You can optionally choose to only allow Qbons to be redeemable by you, the merchant, in store with your scanner by only allowing redemptions with a QR Code.',
      value: couponData.redemptionMethod || redemptionMethods[0]?.name,
      combobox: true,
      options: redemptionMethods.map((method) => method.name),
      onValueChange: (value) => {
        if (value) {
          const newData = { ...couponData, redemptionMethod: value };
          setCouponData(newData);
        }
      },
    },
    {
      label: 'Discount Code',
      value: couponData.code,
      helpTipWritten: 'This code should be a POS system discount code or a code your business has associated with a discount at checkout. If set, this discount code will only appear when the user redeems the Qbon. If redemption is via scan only, then you will have to scan the customers Qbon QR Code and the code will then be revealed only on the merchant side.',
      onValueChange: (code) => {
        const newData = { ...couponData, code };
        setCouponData(newData);
      },
    },
    {
      label: 'Start Date',
      value: couponData.startDate?.toString(),
      helpTipWritten: 'This is the date in which you will begin accepting the discount.',
      type: 'date',
      onValueChange: (startDate) => {
        const newData = {
          ...couponData,
          startDate: new Date(startDate),
        };
        setCouponData(newData);
      },
    },
    {
      label: 'Expire Date',
      helpTipWritten: 'This is the last day you will accept the discount on, after this day the Qbon will automatically expire and be unavailable to all users for both claim and redemption.',
      value: couponData.endDate?.toString(),
      type: 'date',
      onValueChange: (endDate) => {
        const newData = {
          ...couponData,
          endDate: new Date(endDate),
        };
        setCouponData(newData);
      },
    },
  ];

  const executeAction = useCallback(() => {
    if (couponData.name === '' || couponData.description === '') {
      setErrorMessage(['Please make sure to fill in name and description']);
      scrollToTop();
      return;
    }

    createCoupon.mutate(
      {
        ...couponData,
        status: 'AVAILABLE',
        type: 'SHARE',
        file,
        redemptionMethodId:
          redemptionMethods.find(
            (code) => code.name === couponData.redemptionMethod,
          )?.id || 1,
        couponImageUrl,
      },
    );
  }, [
    createCoupon,
    couponData,
    file,
    couponImageUrl,
    redemptionMethods,
  ]);

  const handleImageCropComplete = async (croppedImage: Blob | null, type: string): Promise<void> => {
    if (croppedImage) {
      const croppedFile = new File(
        [croppedImage],
        `cropped_coupon_image.${croppedImage.type}`,
        { type: croppedImage.type },
      );
      if (croppedFile) {
        if (type === 'coupon') {
          uploadCouponCoverImage.mutate(croppedFile);
        }
      }
    }
    setCropModalOpen(false);
  };

  const scrollToTop = () => {
    errorDivRef &&
    errorDivRef.current &&
    (errorDivRef.current as any).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <>
      <div className="flex flex-row pb-3 text-center w-full">
        <div>
          <h1 className="font-bold text-xl px-3 md:px-0 text-center">
            Create {parentCouponId ? 'Shared Qbon' : 'Qbon'}
          </h1>
        </div>
      </div>
      <WarningBox
        message={'Lets create your first Qbon! A Qbon is a discount you give consumers when they share your business with their friends online, exchange reward points to claim a discount, or scan your QR Code at your business.'} />
      <div className="pt-3">
        <Button
          label="I'll create one later!"
          buttonWidth="full"
          loading={createCoupon.status === 'pending'}
          onClick={onComplete}
        />
      </div>
      <div className={'w-full'}>
        <div className="py-1" ref={errorDivRef}>
          <ErrorMessage message={errorMessage} />
        </div>
        <div className="py-3 w-full">
          <BasicForm fields={fields} />
          <div className="inline-flex">
            <div className="pr-1 pt-6">
              <Button
                label="Clear Start Date"
                onClick={() =>
                  setCouponData({ ...couponData, startDate: null })
                }
              />
            </div>
            <div className="pl-1 pt-6">
              <Button
                label="Clear End Date"
                onClick={() => setCouponData({ ...couponData, endDate: null })}
              />
            </div>
          </div>
          <div className="py-6 pt-24">
            <InfoBox
              message={"Let's give your Qbon a cover image, this can be a photo of the product or service your are giving a discount for or something related. Click on 'Choose File' to pick an image from your library or your desktop."} />
            <div className="font-bold pt-6">
              Coupon Image {'(JPEG or PNG - 16:9 Aspect Ration Suggested)'}
            </div>
            <div>
              Give your coupon an image{' '}
              <span className="text-red-400">
                (if not set default will be used)
              </span>
            </div>
            <div className="py-2 inline-flex">
              <input
                type="file"
                onChange={(event: any) => {
                  setCropSrc(URL.createObjectURL(event.target.files[0]));
                  setCropModalOpen(true);
                  setType('coupon');
                }}
              />
            </div>
            <Button
              buttonWidth={"full"}
              label="Remove Image"
              onClick={() => {
                setCouponImageUrl(undefined);
              }}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="flex justify-center">
            <CouponComp
              merchantLogoUrl={merchant.logoImageUrl}
              description={couponData.description!}
              endDate={couponData.endDate!}
              startDate={couponData.startDate}
              code={couponData.code}
              couponImageUrl={couponImageUrl}
              discountAmount={couponData.discountAmount}
              discountType={couponData.discountType}
            />
          </div>
        </div>
        <div className="pt-12">
          <Button
            label="Create Qbon"
            buttonWidth="full"
            loading={createCoupon.status === 'pending'}
            onClick={executeAction}
          />
        </div>
        <div className="pt-3">
          <Button
            label="I'll create one later!"
            buttonWidth="full"
            loading={createCoupon.status === 'pending'}
            onClick={onComplete}
          />
        </div>


        {cropModalOpen && (
          <ImageCropModal
            src={cropSrc}
            aspect={[16, 9]}
            onComplete={(croppedImage: Blob | null) =>
              handleImageCropComplete(croppedImage, type)
            }
            onClose={() => setCropModalOpen(false)}
          />
        )}
      </div>
    </>

  );
}