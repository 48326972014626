import { ChevronRightIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router';
import { useRaffles } from '../../../hooks/raffle.hook';
import Tabs from '../../../common/library/Tabs';
import RaffleInfo from './RaffleInfo';
import RaffleEntries from './RafflesEntries';

export default function RaffleDetailsPage() {
  const navigate = useNavigate();
  const { raffleId } = useParams();

  const { useMerchantSingleRaffleQuery } = useRaffles();

  const { data, isSuccess, refetch } = useMerchantSingleRaffleQuery(parseInt(raffleId || ''));

  return (
    <>
      <div className="flex flex-row">
        <div>
          <h1
            className="font-bold text-xl pb-4 px-2 md:px-0 underline cursor-pointer text-[#fb3310]"
            onClick={() => navigate('/dashboard/giveaways')}
          >
            Giveaways
          </h1>
        </div>
        <div>
          <ChevronRightIcon
            className="h-6 w-6 text-gray-500 pt-1"
            aria-hidden="true"
          />
        </div>
        <div>
          <h1 className="font-bold text-xl pb-4 px-2 md:px-0">
            Giveaway Details
          </h1>
        </div>
      </div>

      <div className={''}>
        {isSuccess &&
          <Tabs
            tabs={[
              {
                category: 'Giveaway',
                title: 'Giveaway',
                content: (
                  <>
                    <RaffleInfo data={data} refetch={() => refetch()} />
                  </>
                ),
              },
              {
                category: 'Entries',
                title: 'Entries',
                content: (
                  <>
                    <RaffleEntries raffleId={data.raffle.id} />
                  </>
                ),
              },

            ]}
          />
        }
      </div>
    </>
  );
}
